import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";

const NotFoundPage = () => {
  useEffect(() => {
    document.body.classList.add("pagenotfound");
  }, []);
  return (
    <Layout>
      <section className="banner h-screen relative">
        <div
          className="banner-img h-full bg-cover flex items-center justify-center"
          style={{ backgroundImage: `url("../images/404.jpg")` }}
        >
          <div className="banner-info py-150 px-50 mt-50 bg-tanlight text-center fade-ani relative md:w-2/3">
            <h1 className="lg:text-60 md:text-46 text-36 lg:leading-80 tracking-006em capitalize leading-50 text-brown">
              404
            </h1>
            <p className="text-dark text-opacity-70 text-14 font-400 leading-[20px] max-w-[470px] m-auto">
              It seems the page you are looking for no longer exists. Visit our
              homepage or use our navigation bar to find what you’re looking
              for.
            </p>
            <div className="text-center mt-30">
              <Link
                className="button button-tan cursor-pointer hamburger menu-open-test transition-all duration-300 "
                to="/"
              >
                go back to home
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
export const Head = () => <title>404 | Sanbona Wildlife Reserve</title>
